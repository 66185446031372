import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Box from 'components/box';
import Head from 'components/head';
import Title from 'components/title';
import Kontakty from 'components/kontakty';
import FullWidth from 'components/fullwidth';
import obr from '../images/ucetnictvi-a-dane.jpg';
import cenik from '../files/CENIK-1-1-2021.pdf';

const Sluzba = ({ data }) => (
  <Layout>
    <FullWidth>
      <Head pageTitle={data.ucetnictviJson.title} />
      <div className="c1c1">
        <div className="column">
          <Title as="h2" size="large">
            {data.ucetnictviJson.title}
          </Title>

          <div
            className="content"
            dangerouslySetInnerHTML={{
              __html: data.ucetnictviJson.content.childMarkdownRemark.html,
            }}
          />
        </div>
        <div className="column lp2">
          <img src={obr} alt="Daně a účetnictví ilustrace" />
        </div>
      </div>

      <div className="cenik">
        <h3 className="kontaktni-osoby">Ceník</h3>
        <div className="download-btn">
          <a
            href={cenik}
            className="homeButton"
            target="_blank"
            rel="noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="black"
              width="18px"
              height="18px"
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z" />
            </svg>
            <span>Stáhnout ceník</span>
          </a>
        </div>
      </div>
      <Box>
        <h3 className="kontaktni-osoby">Kontaktní osoby</h3>
      </Box>
      <Kontakty items={data.ucetnictviJson.kontakty} />
    </FullWidth>
  </Layout>
);

Sluzba.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Sluzba;

export const query = graphql`
  query UcetnictviQuery {
    ucetnictviJson {
      title
      content {
        childMarkdownRemark {
          html
        }
      }
      kontakty {
        name
        occupation
        phone1
        phone2
        email
      }
    }
  }
`;
